import React, { useState, useEffect } from "react";
import PageWrapper from "../Wrapper/Wrapper";

import check from "../../../../Common/Assets/Images/check.png";
import backarrow from "../../../../Common/Assets/Images/backarrow.png";
import ghs from "../../../../Common/Assets/Images/ghana.png";
import us from "../../../../Common/Assets/Images/america.png";
import { useNavigate } from "react-router-dom";
import Constants from "../../../../Utils/Constants";
import axiosInstance from "../../../../setup/axios";
import Select from "react-select";
import alert from "../../../../Common/Assets/Images/exclamation-circle-solid.svg";
import Cookies from "js-cookie";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import momoimage from "../../../../Common/Assets/Icons/momoimage.png";
import closeIcon from "../../../../Common/Assets/Images/Close.png";
import Momo from "./Momo";
import ValidateMomoModal from "./ValidateMomoModal";
import MomoContactDetails from "./MomoContactDetails";

function Buy() {
  const [assets, setAssets] = useState([]);
  const [normalFee, setNormalFee] = useState<any>();
  const [fastFee, setFastFee] = useState<any>();
  const [buyRate, setBuyRate] = useState<any>();
  const [currencyName, setCurrencyName] = useState("");
  const [amount, setAmount] = useState<any>();
  const [address, setAddress] = useState("");
  //eslint-disable-next-line
  const [screenName, setScreenName] = useState("buy");
  const [isOpen, setIsOpen] = useState(false);

  const [selectedPayment, setSelectedPayment] = useState<any>();
  // const [openSelect, setOpenSelect] = useState(true);
  const [limitErrors, setLimitErrors] = useState("");
  //eslint-disable-next-line
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [width, setWidth] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [amountError, setAmountError] = useState("");
  const [checked, setChecked] = useState("normal");
  const [paymentType, setPaymentType] = useState("mtn");
  const [supportedPayments, setSupportedPayments] = useState([]);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(""); // State for total amount
  const [currency, setCurrency] = useState("");
  const [minimumAmount, setMinimumAmount] = useState("");
  const [instock, setInstock] = useState(false);
  const [buyOrder, setBuyOrder] = useState<any>({});
  const [code, setCode] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [openMomoModal, setOpenMomoModal] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState<string>("");
  const [momoOperator, setMomoOperator] = useState<string>("");
  const [showNameDisplay, setShowNameDisplay] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [input, setInput] = useState("");
  const [fullname, setFullName] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [verifyError, setVerifyError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPaymentMehtods = () => {
    axiosInstance
      .get(`${Constants.API_BASE_URL}/payments/user?type=PAYMENT`)
      .then((res) => {
        // console.log(res.data);
        setPaymentMethods(
          res.data.map((payment: any) => ({
            value: payment?.id,
            label: payment?.accountNumber,
            image: payment?.payment?.logoUrl,
          })),
        );
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getPaymentMehtods();
  }, []);

  const verifyName = async () => {
    setIsLoading(true);

    axiosInstance
      .get(
        `${Constants.API_BASE_URL}/payments/account/query?accountNumber=${input}&paymentId=${selectedPaymentId}`,
      )
      .then((res) => {
        // console.log("response", res.data);
        setFullName(res.data.accountName);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log("error", err);
        setVerifyError("There is something wrong with the information entered");
        setIsLoading(false);
      });
  };
  const handleInput = (e: any) => {
    setVerifyError("");
    setInput(e.target.value);
  };

  const handleShowDetails = () => {
    verifyName();
    setShowDetails(true);
    setShowNameDisplay(false);
  };

  const hasErrors =
    !!amountError ||
    !!errorMessage ||
    !checked ||
    !selectedPayment ||
    !address ||
    !amount ||
    limitErrors.includes("upgrade");

  const userLevel = Cookies.get("level") ?? "Limited";
  const level = {
    cap: 300,
    Limited: 300,
    Intermediate: 1000,
    Full: 2000,
  };

  const handleClickMomo = (value: any, id: string, operator: string) => {
    setSelectedPayment(value);
    setSelectedPaymentId(id);
    setMomoOperator(operator);
  };
  useEffect(() => {
    const getPaymentMethods = async () => {
      try {
        const response = await axiosInstance.get(
          `${Constants.API_BASE_URL}/payments/supported?type=MOMO`,
        );
        setSupportedPayments(response.data);
        // console.log("supportedPayments", supportedPayments);
      } catch (error) {
        // console.error("Error loading payment methods:", error);
      }
    };

    getPaymentMethods();
    //eslint-disable-next-line
  }, []);

  const handleContinue = () => {
    setShowNameDisplay(true);
    setOpenMomoModal(false);
  };

  const submitOrder = async (event: React.FormEvent) => {
    event.preventDefault();
    // console.log("brrr");

    if (parseFloat(amount) >= 10000) {
      window.open("https://wa.me/message/HT3MBH3X46FPG1", "_blank");
      return; // Exit the function if Whatsapp redirect is triggered
    }

    try {
      const response = await axiosInstance.post(
        `${Constants.API_BASE_URL}/orders/buy`,
        {
          amount: amount,

          walletId: selectedAssetId,
          rate: Number(buyRate),
          asset: code,
          fee: checked === "normal" ? Number(normalFee) : Number(fastFee),
          feeType:
            checked === "normal"
              ? "normalProcessingFee"
              : "priorityProcessingFee",

          walletAddress: address,
          paymentMethodId: paymentType,
        },
      );

      setBuyOrder(response.data);
      // console.log("buyOrder", buyOrder); // Update state with API response
    } catch (err) {
      // console.error("Error", err);
    }
  };
  // console.log("paymenttype", paymentType);

  useEffect(() => {
    // console.log("buyOrder", buyOrder);
    if (buyOrder.status === "success") {
      navigate("/buy-details", { state: { buyOrder, screenName } });
    }
    //eslint-disable-next-line
  }, [buyOrder]);
  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAmount(Number(value));
  };
  const handleRadioChange = (value: any) => {
    setChecked(value);
  };
  const calculateTotalAmount = () => {
    const enteredAmount = amount;
    let fee = "";
    if (checked === "normal") {
      fee = normalFee;
    } else if (checked === "fast") {
      fee = fastFee;
    }
    const totalInGhs = Number(enteredAmount) * buyRate + Number(fee) * buyRate;
    if (amount) {
      setTotalAmount(totalInGhs.toFixed(2));
    } else {
      setTotalAmount("0");
    }
  };
  useEffect(() => {
    calculateTotalAmount();
    //eslint-disable-next-line
  }, [amount, selectedAssetId, checked]);

  const handleClick = (
    assetId: any,
    normalFee: any,
    fastFee: any,
    buyRate: any,
    currencyName: any,
    currency: any,
    minimumAmount: any,
    instock: any,
    code: any,
  ) => {
    setSelectedAssetId(assetId);

    setNormalFee(normalFee);
    setFastFee(fastFee);
    setBuyRate(buyRate);
    setCurrencyName(currencyName);
    setCurrency(currency);
    setMinimumAmount(minimumAmount);
    setInstock(instock);
    setCode(code);
    setShowForm(true);
  };

  // console.log("instock", instock);

  const canGoBack = window.history.state.idx !== 0;
  const navigate = useNavigate();
  const goBack = (e: any) => {
    e.preventDefault();
    navigate(-1);
  };

  const validateAddress = async (address: string) => {
    try {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/assets/validate/address?address=${address}&currency=${currency}`,
      );
      // console.log("response:", response)
      // console.log("response:", order.currency)
      // setIsValidAddress(response.data);

      if (response.data === false) {
        setErrorMessage("Invalid address. Please enter a valid address.");
        setIsButtonDisabled(true);
      } else {
        setErrorMessage("");
        setIsButtonDisabled(false);
      }
    } catch (error) {
      // console.error("Error validating address:", error);
    }
  };

  useEffect(() => {
    if (parseFloat(amount) >= 10000) {
      setLimitErrors(
        `Contact support on whatsapp for orders 10,000 USD and above`,
      );
    } else if (
      parseFloat(amount) >
      (userLevel === "Limited"
        ? level.Limited
        : userLevel === "Intermediate"
          ? level.Intermediate
          : level.Full)
    ) {
      setLimitErrors(
        `You cannot buy more than  ${
          userLevel === "Limited"
            ? level.cap
            : userLevel === "Intermediate"
              ? level.Intermediate
              : level.Full
        }.00 ${code.toUpperCase()}. Please upgrade your account to increase limit`,
      );
    } else {
      setLimitErrors("");
    }

    // eslint-disable-next-line
  }, [amount]);
  const loadAssets = async () => {
    try {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/assets`,
      );
      setAssets(response.data);
      // console.log("assets", assets);
    } catch (error) {
      // console.error("Error loading assets:", error);
    }
  };

  useEffect(() => {
    loadAssets();
    validateAddress(address);

    // eslint-disable-next-line
  }, [address, selectedAssetId]);

  useEffect(() => {
    if (amount === "0") {
      setAmountError("");
    } else {
      if (minimumAmount > amount) {
        setAmountError(
          `Minimum amount is ${minimumAmount}. Cannot proceed with transaction`,
        );
        setIsButtonDisabled(true);
      } else {
        setAmountError("");
        setIsButtonDisabled(false);
      }
    }
  }, [amount, minimumAmount]);

  // const payment = [
  //   {
  //     value: "mtn",
  //     label: "MTN Mobile Money",
  //     image: momo, // Imported directly without string path
  //   },
  // ];

  // useEffect(() => {
  //   const getPaymentMethods = async () => {
  //     try {
  //       const response = await axiosInstance.get(
  //         `${Constants.API_BASE_URL}/payments/supported?type=MOMO`
  //       );
  //       setSupportedPayments(
  //         response.data.map((payment: any) => ({
  //           value: payment.paymentTypeCode, // Assuming 'id' is the unique identifier
  //           label: payment.name,
  //           image: payment.image,
  //         }))
  //       ); // Map data to select options format
  //     } catch (error) {
  //       console.error("Error loading payment methods:", error);
  //     }
  //   };

  //   getPaymentMethods();
  // }, []);

  // const selectStyles = {
  //   control: (styles: any) => ({
  //     ...styles,
  //     border: "none",
  //     width: "150px",
  //   }),
  // };

  const paymentStyles = {
    control: (styles: any) => ({
      ...styles,
      border: "1px solid #E0E0E0", // Set style (solid) and width (2px)

      height: "53px",
      borderRadius: "10px",
    }),
  };

  // const handleChange = (selectedOption: any) => {
  //   console.log("selectedOption", selectedOption);
  //   setSelectedOption(selectedOption);
  // };

  const handlePaymentMethods = (selectedPayment: any) => {
    // console.log("selectedPayment", selectedPayment);
    setSelectedPayment(selectedPayment);
    setPaymentType(selectedPayment.value);
  };
  // console.log("paymentType", selectedPayment);

  const customeTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "lightblue",
      primary: "#E5E4E2",

      textAlign: "left",
    },
  });

  const handleMomo = () => {
    // console.log("handleMomo");
    setIsOpen(false);
    setOpenMomoModal(true);
    // Open Momo modal
  };

  return (
    <PageWrapper
      content={
        <div className=" items-center lg:items-start md:items-start flex flex-col">
          <div className="lg:w-full w-[350px] flex justify-between ">
            <button
              onClick={(e) => goBack(e)}
              className={` flex cursor-pointer  focus:outline-none ${
                !canGoBack ? "opacity-50" : <> </>
              }`}
              disabled={!canGoBack}
            >
              {" "}
              <div>
                <img src={backarrow} alt="" className="h-3 w-4" />
              </div>
              <div className="ml-3 cursor-pointer text-[12px]">Back</div>
            </button>
          </div>
          <div className=" lg:w-full w-[350px] text-[30px] font-bold text-left mt-4">
            Place A Buy Order
          </div>

          <div className="flex flex-col lg:flex-row md:flex-row items-center lg:items-start md:items-start w-[600px] lg:w-full md:w-full my-10  ">
            {" "}
            {/* Updated here */}
            {/* currencies */}
            <div className="flex flex-col items-start w-[350px] lg:w-[540px] md:w-[540px] border-l border-r border-t rounded-[10px] pt-2 overflow-hidden  overflow-x-visible lg:mt-0 md:mt-2">
              <div className="border-b py-2 px-4 overflow-hidden w-full">
                <li className="text-left"> Select assets to buy</li>
              </div>
              <div className="w-full">
                <ul className="list-none">
                  {assets &&
                    assets.map((asset: any) => (
                      <div
                        key={asset.id} // Use a unique identifier for each item
                        className={`py-3 px-4  border-b  last:overflow-hidden border-${
                          selectedAssetId === asset.walletId
                            ? " border border-theme last:rounded-b-[10px] "
                            : ""
                        }
                               

                            }`}
                        onClick={() =>
                          handleClick(
                            asset.walletId,
                            asset.data.normalProcessingFee,
                            asset.data.priorityProcessingFee,
                            asset.data.buyRate,
                            asset.name,
                            asset.network,
                            asset.data.minimumAmount,
                            asset.data.instock,
                            asset.code,
                          )
                        }
                        style={{
                          background: `${
                            selectedAssetId === asset.walletId
                              ? "rgb(0,80,255, .05)"
                              : "white"
                          }`,
                        }}
                      >
                        <div className="flex w-full cursor-pointer">
                          <div>
                            <img src={asset.icon} alt="" className="w-8 h-8" />
                          </div>
                          <div className="ml-3 items-start flex flex-col">
                            <div>
                              {" "}
                              <li className="text-left text-bold text-[16px]">
                                {asset.name}
                              </li>
                            </div>

                            <div className="flex">
                              <li className="text-left">{asset.code}</li>
                              <li className="text-left bg-green-500 rounded-[5px] ml-2 px-1 text-white text-[14px]">
                                {asset.networkName}
                              </li>
                            </div>
                          </div>
                          {selectedAssetId === asset.walletId && (
                            <img
                              src={check}
                              alt="Selected"
                              className="ml-auto h-6 w-6"
                            />
                          )}
                        </div>
                      </div>
                    ))}
                </ul>
              </div>
            </div>
            {/* form */}
            {showForm ? (
              instock === false ? (
                <div className="flex flex-col items-center w-[350px] lg:w-[540px] md:w-[540px]  border rounded-[10px] mt-6 md:mt-2 lg:mt-0 lg:mx-4 pt-2 ml-0 md:ml-4 p-10">
                  <div className=" flex flex-col items-center ">
                    <div>
                      <img
                        src={alert}
                        className="w-28 h-28 mb-10 mt-10"
                        alt="Alert"
                      />
                    </div>
                    <div className="font-bold">Out of stock</div>
                    <div className="mt-4 mb-10 text-[14px]">
                      We are currently out of stock. Please check back later
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-start w-[350px] lg:w-[540px] md:w-[540px]  border rounded-[10px]  mt-6 md:mt-2 lg:mt-0 lg:mx-4 pt-2 ml-0 md:ml-4 ">
                  <div className="py-2 px-4 w-full">
                    <li className="text-left font-medium">Amount to buy</li>
                  </div>
                  <div className="w-full">
                    <ul className="list-none">
                      <div className="py-2 px-2 border rounded-[10px] mx-4 flex ">
                        <div className="flex ">
                          <img
                            src={us}
                            alt=""
                            className="w-7 h-7 rounded-full"
                          />

                          <input
                            className=" text-[14px] py-2 ml-2 border-none focus:ring-0 focus:border-none"
                            placeholder="USD 0.00"
                            type="text"
                            required
                            value={amount}
                            onChange={handleAmountChange}
                          />
                        </div>
                      </div>
                      {amountError && (
                        <div className="ml-4 mt-2">
                          {" "}
                          <p className="text-red-500 mt-2 text-left ml-2  text-[13px]">
                            {amountError}
                          </p>
                        </div>
                      )}

                      {limitErrors.length > 0 && (
                        <div className="text-red-500 mt-2 text-left text-[13px] ml-4">
                          {limitErrors}
                        </div>
                      )}
                    </ul>

                    <div className="  py-2 px-4  w-full mt-4">
                      <p className="text-left font-medium">
                        {currencyName} wallet address
                      </p>
                      <div className=" ">
                        <textarea
                          className="border rounded-[10px] px-2 py-4  w-full mt-2 h-[110px]  focus:ring-0 focus:border-gray-300"
                          placeholder="Enter Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        {errorMessage && (
                          <p className="text-left text-red-500 text-[13px]">
                            {errorMessage}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* fees */}
                    <div className="py-2 px-4  w-full mt-4">
                      <div className="text-left font-medium">
                        Set Fee Priority
                      </div>
                      <div className="flex items-start">
                        {" "}
                        <div>
                          <input
                            required
                            className="text-theme"
                            readOnly
                            type="radio"
                            name=""
                            id=""
                            checked={checked === "normal"}
                            onClick={() => handleRadioChange("normal")}
                          />{" "}
                          <label className="ml-1">Normal (${normalFee} )</label>
                        </div>
                        <div className="ml-4">
                          <input
                            required
                            className="text-theme"
                            readOnly
                            type="radio"
                            name=""
                            id=""
                            checked={checked === "fast"}
                            onClick={() => handleRadioChange("fast")}
                          />
                          <label className="ml-1">Fast (${fastFee})</label>
                        </div>
                      </div>
                    </div>
                    {/* amount you will get */}
                    <div className="py-2 px-4 overflow-hidden w-full mt-4">
                      <li className="text-left font-medium">
                        You will send <small>( Rate: {buyRate})</small>
                      </li>
                      <div className="py-2 px-2 border rounded-[10px] flex mt-3">
                        <img
                          src={ghs}
                          alt=""
                          className="w-7 h-7 rounded-full"
                        />
                        <div className="font-bold text-[14px] flex py-2 ml-2">
                          <h1>GHS</h1> <div className="ml-2">{totalAmount}</div>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="py-2 px-4  w-full mt-4">
                      {paymentMethods.length > 0 && (
                        <div className="text-left font-medium">
                          {" "}
                          Select payment method
                        </div>
                      )}
                      <div className=" mt-2  ">
                        {paymentMethods.length > 0 && (
                          <Select
                            required
                            formatOptionLabel={(paymentMethods) => (
                              <div className="flex items-center">
                                {paymentMethods?.image && (
                                  <img
                                    src={paymentMethods?.image}
                                    className="w-8 h-8 rounded-full"
                                    alt=""
                                  />
                                )}
                                <span className="text-[14px] font-medium ml-2">
                                  {paymentMethods?.label}
                                </span>
                              </div>
                            )}
                            defaultValue={selectedPayment}
                            onChange={handlePaymentMethods}
                            options={paymentMethods}
                            placeholder={
                              <div className="flex items-start">
                                {" "}
                                {/* <img
                                src={momo}
                                className="w-8 h-8 rounded-full "
                                alt=""
                              />{" "} */}
                                <span className="text-[14px] font-medium ml-2 mt-1">
                                  Select Your Payment Mehtod
                                </span>{" "}
                              </div>
                            }
                            styles={paymentStyles}
                            theme={customeTheme}
                            isSearchable={false}
                            blurInputOnSelect={false}
                          />
                        )}
                      </div>
                      <div>
                        {paymentMethods.length === 0 && (
                          <div
                            className="text-left font-medium mt-4 underline text-[14px] cursor-pointer"
                            onClick={() => setIsOpen(true)}
                          >
                            Add Payment Method
                          </div>
                        )}

                        <Dialog
                          open={isOpen}
                          onClose={() => setIsOpen(false)}
                          className="relative z-50"
                        >
                          <div
                            className="fixed inset-0 bg-black/30"
                            aria-hidden="true"
                          />

                          <div className="fixed inset-0 flex w-screen items-center justify-center p-4  ">
                            <DialogPanel className="max-w-lg space-y-4  bg-white p-12 rounded-[10px]">
                              <div className="flex justify-end">
                                <button onClick={() => setIsOpen(false)}>
                                  <img
                                    src={closeIcon}
                                    alt="Close"
                                    className="w-4 h-4"
                                  />
                                </button>
                              </div>
                              <DialogTitle className="font-bold text-[20px]">
                                Payment Methods
                              </DialogTitle>
                              <div className="flex gap-4 py-4">
                                <div>
                                  {" "}
                                  <img
                                    src={momoimage}
                                    alt=""
                                    className="h-10 w-10"
                                  />
                                </div>
                                <div className="">
                                  {" "}
                                  <Description className="font-semibold">
                                    Mobile Money
                                  </Description>
                                  <p>Proceed to add a mobile money account.</p>
                                </div>
                              </div>
                              <div className="py-6 ">
                                <button
                                  onClick={handleMomo}
                                  className=" bg-blue text-white rounded-[10px] px-4 py-2 w-full"
                                >
                                  Continue
                                </button>
                              </div>
                            </DialogPanel>
                          </div>
                        </Dialog>
                        {openMomoModal && (
                          <Momo
                            openMomoModal={openMomoModal}
                            setOpenMomoModal={setOpenMomoModal}
                            handleClickMomo={handleClickMomo}
                            handleContinue={handleContinue}
                            supportedPayments={supportedPayments}
                            selectedPayment={selectedPayment}
                            setIsOpen={setIsOpen}
                          />
                        )}

                        {showNameDisplay && (
                          <ValidateMomoModal
                            selectedPayment={selectedPayment}
                            selectedPaymentId={selectedPaymentId}
                            momoOperator={momoOperator}
                            showNameDisplay={showNameDisplay}
                            setShowNameDisplay={setShowNameDisplay}
                            input={input}
                            handleInput={handleInput}
                            fullname={fullname}
                            verifyName={verifyName}
                            handleShowDetails={handleShowDetails}
                            setOpenMomoModal={setOpenMomoModal}
                            isLoading={isLoading}
                            verifyError={verifyError}
                          />
                        )}

                        {showDetails && (
                          <MomoContactDetails
                            input={input}
                            selectedPaymentId={selectedPaymentId}
                            momoOperator={momoOperator}
                            fullname={fullname}
                            showDetails={showDetails}
                            setShowDetails={setShowDetails}
                            setShowNameDisplay={setShowNameDisplay}
                          />
                        )}
                      </div>

                      <button
                        className={` ${
                          hasErrors
                            ? "bg-gray-200 text-gray-300"
                            : "bg-blue text-white"
                        } border bg-blue flex rounded-[10px] lg:px-4 py-4 mt-6  items-center justify-center  w-[290px] lg:w-[510px] mb-4 cursor-pointer`}
                        onClick={submitOrder}
                        disabled={hasErrors}
                      >
                        <div
                          className={` text-[15px] text-white cursor-pointer`}
                        >
                          {amount >= 10000
                            ? "Continue on Whatsapp"
                            : "Continue"}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      }
    />
  );
}

export default Buy;
