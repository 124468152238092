import React, { useState } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import backarrow from "../../../Common/Assets/Images/backarrow.png";
import { useNavigate, useLocation } from "react-router-dom";
import Constants from "../../../Utils/Constants";
import axiosInstance from "../../../setup/axios";

function ChangeNow() {
  const canGoBack = window.history.state.idx !== 0;
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [withdrawalAddress, setWithdrawalAddress] = useState("");
  const [showAddressError, setShowAddressError] = useState(false);
  const [showRefundError, setShowRefundError] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [showRefundInput, setShowRefundInput] = useState(false);
  const [refundAddressText, setRefundAddressText] =
    useState("Add Refund Address");
  const [refundAddress, setRefundAddress] = useState("");
  const [refundErrorMessage, setRefundErrorMessage] = useState("");
  const [swapErrorMessage, setSwapErrorMessage] = useState<any>("");

  const hasErrors =
    !withdrawalAddress ||
    swapErrorMessage ||
    refundErrorMessage ||
    addressErrorMessage;
  const goBack = (e: any) => {
    e.preventDefault();
    navigate(-1);
  };

  const validateWithdrawalAddress = async (address: string) => {
    if (address.trim() === "") {
      setShowAddressError(false); // Hide the error message if the address is empty
      return;
    }
    try {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/assets/validate/address?address=${address}&currency=${data.toCurrency?.network}`,
      );

      if (!response.data) {
        setAddressErrorMessage(
          "Invalid address. Please enter a valid address.",
        );
      } else {
        setAddressErrorMessage("");
      }
    } catch (error) {
      // console.error("Error validating address:", error);
    }
  };

  const handleWithdrawalAddressChange = (event: any) => {
    setWithdrawalAddress(event.target.value);
    validateWithdrawalAddress(event.target.value);
  };

  const handleRefundAddressChange = (event: any) => {
    setRefundAddress(event.target.value);
    validateRefundAddress(event.target.value);
  };

  const handleToggleRefundAddress = () => {
    if (showRefundInput) {
      setShowRefundInput(false);
      setRefundAddressText("Add Refund Address");
    } else {
      setShowRefundInput(true);
      setRefundAddressText("Remove Refund Address");
    }
  };

  const validateRefundAddress = async (address: string) => {
    if (address.trim() === "") {
      setShowRefundError(false); // Hide the error message if the address is empty
      return;
    }
    try {
      const response = await axiosInstance.get(
        `${Constants.API_BASE_URL}/assets/validate/address?address=${address}&currency=${data.fromCurrency?.network}`,
      );
      console.log("response:", response);
      // console.log("response:", order.currency)
      // setIsValidAddress(response.data);

      if (response.data === false) {
        setRefundErrorMessage("Invalid address. Please enter a valid address.");
      } else {
        setRefundErrorMessage("");
      }
    } catch (error) {
      //   console.error("Error validating address:", error);
    }
  };

  const handleSwap = async (e: any) => {
    const swapData = {
      fromCurrency: data.fromCurrency?.code,
      toCurrency: data.toCurrency?.code,
      fromNetwork: data.fromCurrency?.network,
      toNetwork: data.toCurrency?.network,
      exchanger: data.exchanger,
      amount: data.fromValue,
      refundAddress: refundAddress,
      withdrawalAddress: withdrawalAddress,
      rateType: data.rateType,
    };
    try {
      const response = await axiosInstance.post(
        `${Constants.API_BASE_URL}/swap/transaction`,
        swapData,
      );
      // setSuccessMessage("Swap successful!");
      console.log("Swap response:", response.data);
      console.log("swap successful");
      navigate("/exchange-details", { state: response.data });
      // console.log("Exchanger:", rates[0]?.exchanger);
      // console.log("Exchanger:", rates[1]?.exchanger);
    } catch (error) {
      setSwapErrorMessage(
        <span className="text-red-500">
          Try again with a different amount.
        </span>,
      );
      // console.error("Error performing swap:", error);
      // console.log("Exchanger:", swapData);
    }
  };

  console.log("dta", data);

  return (
    <PageWrapper
      content={
        <div className="items-center lg:items-center md:items-center flex flex-col">
          <div className="lg:w-[500px] w-[380px] flex justify-between">
            <button
              onClick={goBack}
              className={`flex cursor-pointer focus:outline-none ${
                !canGoBack ? "opacity-50" : ""
              }`}
              disabled={!canGoBack}
            >
              <div>
                <img src={backarrow} alt="" className="h-3 w-4" />
              </div>
              <div className="ml-3 cursor-pointer text-[12px]">Back</div>
            </button>
          </div>
          <div className="lg:w-full w-[350px] text-2xl font-bold mt-4">
            Swap Transaction
          </div>
          <div className="flex justify-center items-center mt-4">
            <img
              className="rounded-xl"
              style={{ width: "30px", height: "30px" }}
              src={data.fromCurrency?.icon}
              alt={data.fromCurrency?.code}
            />
            <span className="text-gray-300 px-2">
              {data.fromCurrency?.code.toUpperCase()}
              {data.fromCurrency?.code === "usdt" && (
                <span
                  style={{ background: "#01A69F", fontSize: "0.875rem" }}
                  className="ml-1 text-white rounded-[10px] px-2"
                >
                  {data.fromCurrency?.network}
                </span>
              )}
            </span>
            <span className="mx-2">-</span>
            <img
              className="rounded-xl"
              style={{ width: "30px", height: "30px" }}
              src={data.toCurrency?.icon}
              alt={data.toCurrency?.code}
            />
            <span className="text-gray-300 px-2">
              {data.toCurrency?.code.toUpperCase()}
            </span>
            {data.toCurrency?.code === "usdt" && (
              <span
                style={{ background: "#01A69F", fontSize: "0.875rem" }}
                className="ml-1 text-white rounded-[10px] px-2"
              >
                {data.toCurrency?.network}
              </span>
            )}
          </div>
          <div className="flex flex-col justify-start items-start">
            <div className="mt-8  text-left">
              Withdrawal Address {data.toCurrency?.code.toUpperCase()}
              {data.toCurrency?.code === "usdt" && (
                <span className="ml-1">
                  ({data.toCurrency?.network.toUpperCase()})
                </span>
              )}
            </div>
            <textarea
              className="border rounded-[10px] h-[110px] lg:w-[500px] w-[380px] px-2 py-4 mt-2"
              placeholder="Enter Withdrawal Address"
              value={withdrawalAddress}
              onChange={(event) => {
                setWithdrawalAddress(event.target.value);
                validateWithdrawalAddress(event.target.value);
              }}
            />

            {addressErrorMessage && (
              <div className="text-left text-red-500 text-[12px]">
                {addressErrorMessage}
              </div>
            )}

            <div className="mt-4 w-full text-left">
              <div
                className="text-blue text-xs underline cursor-pointer"
                onClick={handleToggleRefundAddress}
              >
                {refundAddressText}
              </div>
              {showRefundInput && (
                <div className="mt-4 w-full">
                  <div className=" text-left">
                    Refund Address {data.fromCurrency?.code.toUpperCase()}
                    {data.fromCurrency?.code === "usdt" && (
                      <span className="ml-1">
                        ({data.fromCurrency?.network.toUpperCase()})
                      </span>
                    )}
                  </div>
                  <textarea
                    className="border rounded-[10px] h-[110px] lg:w-[500px] w-[380px] px-2 py-4 mt-2"
                    placeholder="Enter Withdrawal Address"
                    value={refundAddress}
                    onChange={(event) => {
                      setRefundAddress(event.target.value);
                      validateRefundAddress(event.target.value);
                    }}
                  />
                  {refundErrorMessage && (
                    <div className="text-left text-red-500 text-[12px]">
                      {refundErrorMessage}
                    </div>
                  )}
                </div>
              )}
            </div>

            <button
              className={`w-full h-[50px] px-4 rounded-md mt-6 text-white cursor-pointer ${hasErrors ? "bg-gray-200 text-gray-300 cursor-not-allowed" : "bg-blue text-white"}`}
              onClick={handleSwap}
            >
              Swap Crypto
            </button>
            {swapErrorMessage && (
              <div className="text-left text-red-500 text-[12px]">
                {swapErrorMessage}
              </div>
            )}
          </div>
        </div>
      }
    />
  );
}

export default ChangeNow;
