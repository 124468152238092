import React, { FormEvent, useState } from "react";
import { camera } from "../../../../../../Common/Assets/Icons";
import Input from "../../../../../../Common/Components/Input/Input";
import Button from "../../../../../../Common/Components/Button/Button";
import Select from "../../../../../../Common/Components/Input/Select";
import axiosInstance from "../../../../../../setup/axios";
import Constants from "../../../../../../Utils/Constants";
import Loading from "../../../../../../Common/Components/Loading/Loading";
import Notify from "../../../../../../Helpers/Toast";
import { useNavigate } from "react-router-dom";

function KycThree() {
  const navigate = useNavigate();
  const idType = [
    {
      id: "passport",
      name: "Passport",
    },
    {
      id: "national_identity_card",
      name: "Ghana Card",
    },
    {
      id: "driving_license",
      name: "Driving License",
    },
  ];
  const [state, setState] = useState({
    details: {
      cardType: "passport",
      cardNumber: "",
      cardFront: "",
      cardBack: "",
      selfie: {},
    },
    isRequesting: false,
  });
  const [selfie, setSelfie] = useState<File | null>(null);
  const [selfieUrl, setSelfieUrl] = useState("");

  const [front, setFront] = useState<File | null>(null);
  const [frontUrl, setFrontUrl] = useState("");

  const [back, setBack] = useState<File | null>(null);
  const [backUrl, setBackUrl] = useState("");

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const file = e.target?.files?.[0];
    if (name === "selfie") {
      file && setSelfie(file);
      setSelfieUrl(file ? URL.createObjectURL(file) : "");
    }
    if (name === "front") {
      file && setFront(file);
      setFrontUrl(file ? URL.createObjectURL(file) : "");
    }
    if (name === "back") {
      file && setBack(file);
      setBackUrl(file ? URL.createObjectURL(file) : "");
    }
  };

  const VerifyAccount = (e: FormEvent) => {
    setState({ ...state, isRequesting: true });
    e.preventDefault();

    if (
      (state.details.cardType === "passport" &&
        front &&
        selfie &&
        state.details.cardNumber) ||
      (state.details.cardType !== "passport" &&
        back &&
        front &&
        selfie &&
        state.details.cardNumber)
    ) {
      const formData = new FormData();
      if (front && selfie) {
        formData.append("front", front);
        formData.append("selfie", selfie);
        formData.append("idType", state.details.cardType);
        formData.append("id", state.details.cardNumber);
        if (back) {
          formData.append("back", back);
        }
      }

      axiosInstance
        .post(
          `${Constants.API_BASE_URL}/users/kyc/identity/verification`,
          formData,
        )
        .then(() => {
          setState({ ...state, isRequesting: false });
          navigate("/verfiy-account/3");
        })
        .catch(() => {
          setState({ ...state, isRequesting: false });
          Notify("top-center", "Sorry, something went wrong.");
        });
    } else {
      setState({ ...state, isRequesting: false });
      Notify("top-center", "Form is not full");
    }
  };

  return (
    <form
      onSubmit={(e) => VerifyAccount(e)}
      className="w-full md:w-[700px] m-auto"
    >
      {state.isRequesting && <Loading />}
      <div className="w-full flex flex-col items-start">
        <div
          onClick={() => navigate("/verify-account/1")}
          className="flex text-theme mb-2"
        >
          <i className="fa fa-arrow-left mr-2"></i>
          <b>Go Back</b>
        </div>
      </div>
      <h1 className="font-semibold text-xl text-left" title="Upload Documents">
        Documents upload
      </h1>
      {(front || back || selfie) && (
        <section className="flex w-full overflow-x-auto h-fit">
          <div className="w-fit md:w-full flex md:grid grid-cols-3 gap-2 h-[200px]">
            {frontUrl && (
              <section className="w-[240px] md:w-full h-[150px] flex flex-col items-start my-3">
                <div className="w-full flex j-c-b">
                  <h1>Card (Front)</h1>
                </div>
                <div className="w-full h-full border rounded-md group relative">
                  <img src={frontUrl} alt="" className={`img-fit`} />
                  <aside className="scaleanimate absolute top-0 right-0 left-0 bottom-0 bg-[rgba(0,0,0,0.62)] hidden group-hover:flex items-center j-c-c">
                    <p
                      className="text-white text-xl hover:text-themeLight cursor-pointer"
                      onClick={() => document.getElementById("front")?.click()}
                    >
                      change
                    </p>
                  </aside>
                </div>
              </section>
            )}

            {backUrl && (
              <section className="w-[240px] md:w-full h-[150px] flex flex-col items-start my-3">
                <div className="w-full flex j-c-b">
                  <h1>Card (Back)</h1>
                </div>
                <div className="w-full h-full border rounded-md relative group">
                  <img src={backUrl} alt="" className={`img-fit`} />
                  <aside className="scaleanimate absolute top-0 right-0 left-0 bottom-0 bg-[rgba(0,0,0,0.62)] hidden group-hover:flex items-center j-c-c">
                    <p
                      className="text-white text-xl hover:text-themeLight cursor-pointer"
                      onClick={() => document.getElementById("back")?.click()}
                    >
                      change
                    </p>
                  </aside>
                </div>
              </section>
            )}

            {selfieUrl && (
              <section className="w-[240px] md:w-full h-[150px]  flex flex-col items-start my-3">
                <div className="w-full flex j-c-b">
                  <h1>Selfie Image</h1>
                </div>
                <div className="w-full h-full border rounded-md relative group">
                  <img src={selfieUrl} alt="" className={`img-fit`} />
                  <aside className="scaleanimate absolute top-0 right-0 left-0 bottom-0 bg-[rgba(0,0,0,0.62)] hidden group-hover:flex items-center j-c-c">
                    <p
                      className="text-white text-xl hover:text-themeLight cursor-pointer"
                      onClick={() => document.getElementById("selfie")?.click()}
                    >
                      change
                    </p>
                  </aside>
                </div>
              </section>
            )}
          </div>
        </section>
      )}

      <div className="grid gap-6 md:grid-cols-2 mt-6">
        <div className="flex flex-col items-start w-full">
          <p>Identity Card</p>
          <Select
            style={{ minWidth: "100%" }}
            className="w-full min-h-[45px] md:min-h-[60px] rounded-md md:rounded-[15px] border mt-3 border-gray-300 px-2"
            options={idType}
            value={state.details.cardType}
            setValue={(val) =>
              setState({
                ...state,
                details: { ...state.details, cardType: val },
              })
            }
          />
        </div>
        <div className="flex flex-col items-start w-full">
          <p>Identity Card Number</p>
          <Input
            style={{ minWidth: "100%" }}
            type="text"
            placeholder="Enter identity card number"
            required
            value={state.details.cardNumber}
            setValue={(val) =>
              setState({
                ...state,
                details: { ...state.details, cardNumber: val },
              })
            }
          />
        </div>
        <div className="flex w-full rounded-xl border border-dashed border-[#80808067] a-i-s py-5 pl-5 bg-gray1 relative">
          <div className="flex">
            <img src={camera} alt="" width={30} />
            <div className="flex flex-col a-i-s ml-3">
              <p>Capture Identity card </p>
              <small>Front</small>
            </div>
          </div>
          <input
            id={`front`}
            onChange={(e) => handleFile(e, "front")}
            className={`absolute top-0 right-0 bottom-0 left-0 w-full h-full opacity-0`}
            type="file"
            name=""
          />
        </div>
        <div
          className={`flex w-full rounded-xl border border-dashed border-[#80808067] a-i-s py-5 pl-5 ${state.details.cardType === "passport" ? "bg-[rgba(244,244,244,0.31)] text-gray-300 pointer-events-none" : "bg-gray1"} relative`}
        >
          <div className="flex">
            <img src={camera} alt="" width={30} />
            <div className="flex flex-col a-i-s ml-3">
              <p>Capture Identity card </p>
              <small>Back</small>
            </div>
          </div>
          <input
            id={`back`}
            onChange={(e) => handleFile(e, "back")}
            className={`absolute top-0 right-0 bottom-0 left-0 w-full h-full opacity-0`}
            type="file"
            name=""
          />
        </div>
        <div className="flex w-full rounded-xl border border-dashed border-[#80808067] a-i-s py-5 pl-5 bg-gray1 relative">
          <div className="flex">
            <img src={camera} alt="" width={30} />
            <div className="flex flex-col a-i-s ml-3">
              <p>Capture Selfie</p>
              <small></small>
            </div>
          </div>
          <input
            id={`selfie`}
            onChange={(e) => handleFile(e, "selfie")}
            className={`absolute top-0 right-0 bottom-0 left-0 w-full h-full opacity-0`}
            type="file"
            name=""
          />
        </div>
        {/* <div className="flex w-full rounded-xl border border-dashed border-[#80808067] a-i-s py-5 pl-5 bg-gray1">
                <div className="flex">
                    <div className="flex flex-col a-i-s ml-3">
                        <h1 className='font-normal text-2xl'>Other Uploads</h1>
                        <small className='text-left pr-3 mt-2'>
                            On desktop, Simply drag and drop your files
                            (utility bill, birth certificate etc.) in this field.
                            Acceptable formats: JPEG, JPG or PNG.
                        </small>
                    </div>
                </div>
            </div> */}
      </div>

      <div className="w-full mt-8 mb-10">
        <Button
          style={{ minWidth: "200px" }}
          text={"Save and Continue"}
          onClick={() => {}}
          className={`bg-blue text-white`}
        />
      </div>
    </form>
  );
}

export default KycThree;
