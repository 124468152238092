import React, { useState, useEffect } from "react";
import axiosInstance from "../../setup/axios";
import Constants from "../../Utils/Constants";
import PageWrapper from "./Components/Wrapper/Wrapper";
import arrow from "../../Common/Assets/Images/arrow-right.png";
import refresh from "../../Common/Assets/Images/refresh.png";
import backarrow from "../../Common/Assets/Images/backarrow.png";
import { dotSpinner } from "ldrs";
import ExchangeDetails from "./Components/ExchangeModal/ExchangeDetailsModal";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

function MoreSwapTransactions() {
  return <PageWrapper content={<Content />} />;
}

function Content() {
  const [swapTransactions, setSwapTransactions] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  //eslint-disable-next-line
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  //eslint-disable-next-line
  const [refreshData, setRefreshData] = useState(null);
  //eslint-disable-next-line
  const [selectedTransactionId, setSelectedTransactionId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  dotSpinner.register();

  useEffect(() => {
    fetchByPagination(currentPage);
  }, [currentPage, data]);

  useEffect(() => {
    if (refreshData) {
      fetchByPagination(currentPage);
    }
  }, [refreshData, currentPage, data]);

  const handleTransactionClick = (transactionId: string, responseData: any) => {
    const selectedTransaction = responseData.find(
      (transaction: any) => transaction.id === transactionId,
    );

    if (selectedTransaction) {
      navigate("/exchange-details", { state: selectedTransaction });
      setIsModalOpen(true);
      setSelectedTransactionId(transactionId);
    }
  };

  const fetchByPagination = (count: number) => {
    axiosInstance
      .get(
        `${
          Constants.API_BASE_URL
        }/swap/transactions?limit=${itemsPerPage}&skip=${
          (count - 1) * itemsPerPage
        }`,
      )
      .then((res) => {
        const swapData = res.data.data;
        setSwapTransactions(swapData);
        setTotalTransactions(res.data.total);
        setTotalPages(Math.ceil(res.data.total / itemsPerPage));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  function getStatusColorClass(status: string) {
    switch (status) {
      case "waiting":
        return "inline-block bg-green-200 text-green-500 px-3 rounded-full text-sm align-middle ";
      case "confirming":
      case "exchanging":
        return "bg-cyan-200 text-cyan-500 inline-block align-middle  px-3 rounded-full text-sm";
      case "sending":
        return "bg-purple-200 inline-block align-middle  text-purple-500 px-3 rounded-full text-sm";
      case "finished":
        return "bg-green-200 inline-block align-middle  text-green-500 px-3 rounded-full text-sm";
      case "failed":
        return "bg-red-200 inline-block align-middle  text-red-500 px-3 rounded-full text-sm";
      case "refunded":
        return "bg-gray-200 inline-block align-middle text-gray-500 px-3 rounded-full text-sm";
      default:
        return ""; // Default style
    }
  }

  return (
    <div className="flex flex-col min-h-screen ">
      <section className="flex-1 w-full">
        {isModalOpen && (
          <ExchangeDetails
            isOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            responseData={data}
          />
        )}
        <button
          onClick={() => navigate("/swap")}
          className="flex cursor-pointer"
        >
          <div>
            <img src={backarrow} alt="" className="h-4 w-6" />
          </div>
          <div className="ml-3 cursor-pointer">Back</div>
        </button>
        <div className="flex flex-col items-start w-full mt-4">
          <div className="flex justify-between w-full">
            <div>
              <h1 className="font-semibold text-3xl">Your Swap Transactions</h1>
            </div>
            <div
              onClick={() => {
                setIsLoading(true);
                fetchByPagination(currentPage);
              }}
            >
              <img src={refresh} alt="" className="cursor-pointer h-6 w-6" />
            </div>
          </div>
          <small>
            Table showing page {currentPage} of your swap transactions
          </small>
          {isLoading ? (
            <div className="mx-auto w-full my-auto">
              <div className="pt-6">
                <l-dot-spinner
                  size="80"
                  speed="0.9"
                  color="black"
                ></l-dot-spinner>
              </div>
              <div>
                <div className="mt-8">
                  <h2 className="p-8">Loading...</h2>
                </div>
              </div>
            </div>
          ) : (
            <ul className="w-full">
              {swapTransactions.map(
                (transaction: any, index: number, responseData: any) => (
                  <li
                    key={transaction.id}
                    onClick={() =>
                      handleTransactionClick(transaction.id, responseData)
                    }
                    className="cursor-pointer"
                  >
                    <div
                      className={`flex justify-between py-2 ${
                        index !== swapTransactions.length - 1
                          ? "border-b border-gray-200"
                          : ""
                      }`}
                    >
                      <div className="flex">
                        <div className="flex flex-col my-2 mr-4">
                          <div className="relative">
                            <img
                              src={transaction.fromCurrencyIconUrl}
                              alt=""
                              className="w-[40px] h-[40px] rounded-[2px] relative z-10"
                            />
                            <img
                              src={transaction.toCurrencyIconUrl}
                              alt=""
                              className="w-[40px] h-[40px] rounded-md relative z-10 absolute top-0 left-0 -mt-7 ml-4"
                            />
                          </div>
                        </div>

                        <div className="">
                          <div className="flex">
                            <p className="font-medium">
                              {transaction.fromCurrency.toUpperCase()}
                              {transaction.fromCurrency === "usdt" && (
                                <small className="ml-1 items-center bg-green-500 px-2 mx-2 rounded text-white">
                                  {transaction.fromNetwork.toLowerCase()}
                                </small>
                              )}
                            </p>{" "}
                            <img src={arrow} alt="" className="h-4 w-4 mx-2" />
                            <p className="font-medium">
                              {transaction.toCurrency.toUpperCase()}
                              {transaction.toCurrency === "usdt" && (
                                <small className="ml-1 items-center bg-green-500 px-2 mx-2 rounded text-white">
                                  {transaction.toNetwork.toLowerCase()}
                                </small>
                              )}
                            </p>{" "}
                          </div>

                          <small className="text-gray-400 ">
                            {dayjs(new Date(transaction.createdAt)).format(
                              "MMM D, YYYY hh:mm a",
                            )}
                          </small>
                        </div>
                      </div>

                      <div className="ml-20 flex flex-col items-end">
                        <p>{transaction.amount}</p>
                        <small
                          className={`my-2 ${getStatusColorClass(
                            transaction.status,
                          )}`}
                        >
                          {transaction.status}
                        </small>
                      </div>
                    </div>
                  </li>
                ),
              )}
            </ul>
          )}
        </div>
      </section>

      <div className="flex justify-between w-full p-4 bg-white">
        <button
          onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          disabled={currentPage === 1}
          className="flex min-w-[90px] cursor-pointer focus:outline-none disabled:cursor-not-allowed"
        >
          <i className="fa fa-arrow-left mr-2"></i>
          <p>Previous</p>
        </button>
        <span className="mx-4 text-medium font-medium">Page {currentPage}</span>
        <button
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          disabled={currentPage === totalPages}
          className="flex cursor-pointer focus:outline-none disabled:cursor-not-allowed"
        >
          <p>Next</p>
          <i className="fa fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  );
}

export default MoreSwapTransactions;
