import React, { useState } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import { useNavigate, useLocation } from "react-router-dom";
import backarrow from "../../../Common/Assets/Images/backarrow.png";
import exolix from "../../../Common/Assets/Images/exolix.jpeg";
import changenow from "../../../Common/Assets/Images/changenow.webp";
import copyIcon from "../../../Common/Assets/Images/Copy.png";
import refresh from "../../../Common/Assets/Images/refresh.png";

import Countdown from "react-countdown";
import dayjs from "dayjs";
import { dotSpinner } from "ldrs";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";

function ExchangeDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const initialData = location.state;
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const canGoBack = window.history.state.idx !== 0;
  const [isCopied, setIsCopied] = useState(false);

  dotSpinner.register();

  console.log("data", data);
  const goBack = (e: any) => {
    e.preventDefault();
    navigate("/swap");
  };
  const exchangerImage = data?.exchanger === "exolix" ? exolix : changenow;

  const fetchDataDetails = (dataId: any) => {
    setIsLoading(true);

    axiosInstance
      .get(`${Constants.API_BASE_URL}/swap/transactions/${dataId}`)
      .then((res) => {
        setData(res.data);
        // const counter = dayjs(res.data?.createdAt).add(20, 'minute')

        // setTimer(counter.$m)
        // console.log("counter", res.data);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = data?.depositAddress;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  function getStatusColorClass(status: string) {
    switch (status) {
      case "waiting":
        return "inline-block bg-green-200 text-green-400 px-2 mx-2 rounded-full text-sm align-middle";
      case "confirming":
      case "exchanging":
        return "bg-cyan-200 text-cyan-400 inline-block align-middle px-2  mx-2rounded-full text-sm";
      case "sending":
        return "bg-purple-200 inline-block align-middle text-purple-400 px-2 mx-2 rounded-full text-sm";
      case "finished":
        return "bg-green-200 inline-block align-middle text-green-400 px-2 mx-2 rounded-full text-sm";
      case "failed":
        return "bg-red-200 inline-block align-middle text-red-400 px-2 mx-2 rounded-full text-sm";
      case "refunded":
        return "bg-gray-200 inline-block align-middle text-gray-400 px-2 mx-2  rounded-full text-sm";
      default:
        return ""; // Default style
    }
  }

  return (
    <PageWrapper
      content={
        <div className="items-center lg:items-center md:items-center flex flex-col">
          <div className="lg:w-[500px] w-[380px]">
            <div className="flex justify-between lg:w-[500px] w-[380px]">
              <div>
                <button
                  onClick={goBack}
                  className={`flex cursor-pointer focus:outline-none ${
                    !canGoBack ? "opacity-50" : ""
                  }`}
                  disabled={!canGoBack}
                >
                  <div>
                    <img src={backarrow} alt="" className="h-3 w-4" />
                  </div>
                  <div className="ml-3 cursor-pointer text-[12px]">Back</div>
                </button>
              </div>
              <div
                onClick={() => fetchDataDetails(data.id)}
                className="flex items-center"
              >
                <img src={refresh} alt="" className="cursor-pointer h-4 w-4" />
              </div>
            </div>

            <div className="mt-4">
              <Countdown
                date={dayjs(new Date(data?.createdAt))
                  .add(20, "minute")
                  .valueOf()}
                zeroPadTime={2}
                renderer={({ formatted: { hours, minutes, seconds } }) => {
                  // Render countdown
                  if (data?.status === "waiting") {
                    return (
                      <div>
                        <div className="flex justify-between w-full mb-4 mt-8 border-b ">
                          <div className="font-bold mb-2">Expires In</div>
                          <div className="countdown-container flex justify-end items-center border border-gray-200 rounded-[10px] mb-2">
                            <div className="countdown-item flex flex-col px-2">
                              <span className="font-bold text-xl">
                                {minutes}
                              </span>
                              {/* <span className="text-sm"> Minutes</span> */}
                            </div>
                            <span className="text-xl">:</span>
                            <div className="countdown-item flex flex-col px-2">
                              <span className="font-bold text-xl">
                                {seconds}
                              </span>
                              {/* <span className="text-sm">Seconds</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                }}
              />

              <div className="flex flex-col justify-start items-start w-full">
                <div className="flex items-center justify-between w-full ">
                  <div className="font-bold text-lg">
                    <div className="flex ">
                      <div className="font-bold text-lg">Send</div>

                      <span className="font-bold text-lg px-2 text-green-700">
                        {data?.amount}
                      </span>
                      {data?.fromCurrency}
                      <span className={getStatusColorClass(data?.status)}>
                        {data?.status}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-4">To {data?.fromCurrency} address</div>
              </div>

              {isLoading ? (
                <div className="flex flex-col justify-center items-center min-h-[450px] min-w-[390px] p-6">
                  <div className="mb-4">
                    <l-dot-spinner
                      size="80"
                      speed="0.9"
                      color="black"
                    ></l-dot-spinner>
                  </div>
                  <div>
                    <h2 className="p-8">Loading...</h2>
                  </div>
                </div>
              ) : (
                <>
                  <div className="border flex justify-between rounded-[27px] px-2 mt-2">
                    <div className="text-sm py-2">{data?.depositAddress}</div>
                    <div
                      onClick={copyToClipboard}
                      className="text-sm border-l border-gray-200 pl-2 py-2 flex items-center overflow-hidden cursor-pointer"
                    >
                      <button>
                        <img src={copyIcon} alt="Copy" className="w-4 h-4" />
                      </button>
                      {isCopied ? (
                        <span className="ml-1 text-xs text-green-500">
                          Copied
                        </span>
                      ) : (
                        <span className="ml-1">Copy</span>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col items-start justify-start">
                    <div className="uppercase font-medium py-2">
                      {data?.amount}
                      {data?.fromCurrency} ≈ {data?.amountToReceive}
                      {data?.toCurrency}
                    </div>

                    <div className="text-gray-400">
                      Transaction Id:{" "}
                      <span className="text-green-700">{data?.id}</span>
                    </div>
                    <div className="font-semibold text-base mt-4">
                      Transaction Info
                    </div>
                  </div>

                  <div className="border rounded-[27px] py-8 my-2 ">
                    <div className=" px-6 ">
                      <div className="flex flex-col items-start justify-start px-2 mb-2 mt-[-40] ">
                        {" "}
                        <small style={{ fontSize: "12px" }}>You send</small>
                      </div>
                      <div className="bg-gray-100 flex justify-between py-2 rounded-[27px] px-4 ">
                        <div className="flex ">
                          <img
                            src={data?.fromCurrencyIconUrl}
                            alt="Bitcoin"
                            className="w-6 h-6 "
                          />
                          <div className="pl-2">{data?.amount}</div>
                        </div>

                        <div className="uppercase">{data?.fromCurrency}</div>
                      </div>
                      <div
                        style={{ fontSize: "12px" }}
                        className="text-gray-400 flex flex-col items-start justify-start px-2 mb-4 "
                      >
                        {data?.depositAddress}
                      </div>
                    </div>

                    <div className=" border-t px-6 ">
                      <div className="flex flex-col items-start justify-start px-2 mt-4 mb-2">
                        {" "}
                        <small style={{ fontSize: "12px" }}>You get</small>
                      </div>
                      <div className="bg-gray-100 flex justify-between  py-2 rounded-[20px] px-4">
                        <div className="flex">
                          <img
                            src={data?.toCurrencyIconUrl}
                            alt="Bitcoin"
                            className="w-6 h-6 "
                          />
                          <div className="pl-2">{data?.amountToReceive}</div>
                        </div>

                        <div className="uppercase">{data?.toCurrency}</div>
                      </div>
                      <div
                        style={{ fontSize: "12px" }}
                        className="text-gray-400 flex flex-col items-start justify-start px-2"
                      >
                        {data?.receiveAddress}
                      </div>
                    </div>
                  </div>

                  <div>
                    {data.exchanger && (
                      <div className="flex flex-col items-start justify-start">
                        <div className="font-medium pb-2">Exchanger</div>
                        <div className="flex items-center">
                          <img
                            src={exchangerImage}
                            alt=""
                            className="w-6 h-6 mr-2 rounded-full"
                          />
                          <div className="font-medium">
                            {data.exchanger === "change_now"
                              ? "Change Now"
                              : "Exolix"}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
}

export default ExchangeDetails;
