import React, { useState } from "react";
import PageWrapper from "../Components/Wrapper/Wrapper";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../setup/axios";
import Constants from "../../../Utils/Constants";
import dayjs from "dayjs";
import { dotSpinner } from "ldrs";
import {
  NoUsers,
 
} from "../../../Pages/Main/Components/SideBar/Assets/Icons";

function ReferralPage() {
  dotSpinner.register();

  const limit = 10;
  const [page, setPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const fetchUserPoints = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/users/points`
    );
    return response.data;
  };

  const fetchUserReferrals = async () => {
    const response = await axiosInstance.get(
      `${Constants.API_BASE_URL}/users/referrals?limit=${limit}&skip=${page}`
    );
    return response.data;
  };

  const { data, } = useQuery({
    queryKey: ["points"],
    queryFn: fetchUserPoints,
  });

  const { data: referrals, isLoading: isLoadingReferrals } = useQuery({
    queryKey: ["referrals", page],
    queryFn: fetchUserReferrals,
  });

  const handlePrevPage = () => {
    setPage(page - limit);
    setPageNumber(pageNumber - 1);
  };

  const handleNextPage = () => {
    if (referrals?.hasMore) {
      setPage(page + limit);
      setPageNumber(pageNumber + 1);
    }
  };

  // console.log("data", referrals);

  return (
    <PageWrapper
      content={
        <div className="flex flex-col items-start w-full gap-10">
          <div>
            <h1 className="font-semibold text-3xl">Referrals</h1>
          </div>
          <div className="flex  flex-col md:flex-row w-full gap-10">
            <div className="flex min-h-full items-center justify-center w-full">
              <div className="w-full max-w-md rounded-[10px] border border-gray-300 p-4 flex flex-col items-start">
                <div className="text-base/7 font-medium text-gray">
                  No. of Referrals
                </div>
                <div className="mt-2 text-[54px] font-bold">
                { referrals?.total || 0 }

                </div>
              </div>
            </div>

            <div className="flex min-h-full items-center justify-center w-full">
              <div className="w-full max-w-md rounded-[10px] border border-gray-300 p-4 flex flex-col items-start">
                <div className="text-base/7 font-medium text-gray">
                  Points Earned
                </div>
                <div className="mt-2 text-[54px] font-bold">
                  {data?.points || 0}{" "}
                  <span className="font-bold text-[40px]"> pts</span>
                </div>
              </div>
            </div>

            <div className="flex min-h-full items-center justify-center w-full">
              <div className="w-full max-w-md rounded-[10px] border border-gray-300 p-4 flex flex-col items-start">
                <div className="text-base/7 font-medium text-gray">
                  Points Used
                </div>
                <div className="mt-2 text-[54px] font-bold">
                  {data?.totalPointsRedeemed || 0}{" "}
                  <span className="font-bold text-[40px]"> pts</span>
                </div>
              </div>
            </div>
          </div>

          {/* table */}

          <div className="mt-8 w-full flex flex-col items-start relative pb-16 h-[750px] ">
            <h2 className="text-lg font-semibold mb-4">Referred Users</h2>
            <div className="overflow-x-auto mt-6 flex flex-col items-start w-full border border-gray-200 rounded-[10px] ">
              <table className="w-full bg-white ">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="py-5 px-4 border-b text-left text-gray-500">
                      FULL NAME
                    </th>
                    <th className="py-5 px-4 border-b text-left text-gray-500">
                      REGISTRATION DATE
                    </th>
                    <th className="py-5 px-4 border-b text-left text-gray-500">
                      POINTS EARNED
                    </th>
                  </tr>
                </thead>
                {isLoadingReferrals ? (
                  <tbody className=" w-full ">
                 
                    <td colSpan={3}>
                      <div className="mx-auto text-center my-auto">
                        <div className="pt-6">
                          <l-dot-spinner
                            size="80"
                            speed="0.9"
                            color="black"
                          ></l-dot-spinner>
                        </div>
                        <div>
                          <div className="mt-8">
                            <h2 className="p-8">Loading...</h2>
                          </div>
                        </div>
                      </div>
                    </td>
                
                  </tbody>
                ) : referrals?.data?.length > 0 ? (
                  <tbody className="">
                    {referrals?.data?.map((referral: any, index: any) => (
                      <tr key={index}>
                        <td className="py-4 px-4 border-b text-left">
                          {referral.firstName} {referral.lastName}
                        </td>
                        <td className="py-4 px-4 border-b text-left">
                          {dayjs(referral.createdAt).format(
                            "MMM D, YYYY h:mm A"
                          )}
                        </td>
                        <td className="py-4 px-4 border-b text-left">100</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody className=" h-[550px]">
                    <tr>
                      <td colSpan={3} className=" ">
                        <div className="flex flex-col items-center justify-center text-center">
                        <div> <img src={NoUsers} className="w-[70px] h-[70px]" alt=""/></div>
                       <div className="mt-4 text-gray-600"> You have no referrals yet</div>
                       </div>
                      </td>
                    </tr>
                  </tbody>)}

             
              </table>
            </div>

            {/* Pagination UI */}
            <div className="flex justify-between w-full p-4 bg-white absolute bottom-0 inset-x-0">
              <button
                onClick={handlePrevPage}
                disabled={pageNumber === 0}
                className="flex min-w-[90px] cursor-pointer focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                <i className="fa fa-arrow-left mr-2"></i>
                <p>Previous</p>
              </button>
              <span className="mx-4 text-medium font-medium">
                Page {pageNumber + 1}
              </span>
              <button
                onClick={handleNextPage}
                disabled={!referrals?.hasMore}
                className="flex cursor-pointer focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                <p>Next</p>
                <i className="fa fa-arrow-right ml-2"></i>
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default ReferralPage;
