import React, { FormEvent, useEffect, useState } from "react";
import Input from "../../../../../../Common/Components/Input/Input";
import Button from "../../../../../../Common/Components/Button/Button";
import Notify from "../../../../../../Helpers/Toast";
import axiosInstance from "../../../../../../setup/axios";
// import Constants from '../../../../../../Utils/Constants'
import { useNavigate } from "react-router-dom";
import Constants from "../../../../../../Utils/Constants";
// import Constants from '../../../../../../Utils/Constants'


export type Kycprops = {
  setStep: (step: number, data: any) => void;
  userInfo: {
    phone: string;
    gender: string;
    dob: string;
    state: string;
    city: string;
    address?: string;
    country?: string;
  };
};

function KycOne({ setStep, userInfo }: Kycprops) {
  const navigate = useNavigate();
  const [state, setState] = useState({
    details: {
      phone: "",
      gender: "",
      dob: "",
      state: "",
      city: "",
      address: "",
      country: "",
    },
    isRequesting: false,
  });

  useEffect(() => {
    return () => {
      try {
        setState((prev) => {
          return {
            ...prev,
            details: {
              ...prev.details,
              gender: userInfo?.gender,
              dob: userInfo?.dob,
              phone: userInfo?.phone,
              city: userInfo?.city,
              state: userInfo?.state,
              address: userInfo?.address || "",
              country: userInfo?.country || "",
            },
          };
        });
      } catch (e) {
        return;
      }
    };
    // eslint-disable-next-line
  }, []);

  const formFull = () => {
    if (
      state.details.address &&
      state.details.country &&
      state.details.gender &&
      state.details.dob &&
      state.details.phone &&
      state.details.state &&
      state.details.city
    ) {
      return true;
    }
    return false;
  };

  const nextStep = (e: FormEvent) => {
    e.preventDefault();
    if (formFull()) {
      let data = {
        dateOfBirth: state.details?.dob,
        gender: state.details?.gender,
        country: state.details.country,
        state: state.details.state,
        address: state.details.address,
        city: state.details.city,
      };

      setState({ ...state, isRequesting: true });
      axiosInstance
        .patch(`${Constants.API_BASE_URL}/users`, data)
        .then(() => {
          setStep(2, data);
          navigate(`/verify-account/2`);
        })
        .catch(() => {
          Notify("top-right", "Sorry, Something went wrong", "error");
          setState({ ...state, isRequesting: false });
        });
    } else {
      Notify("top-center", "Please all fields are required");
    }
  };

  return (
    <form onSubmit={(e) => nextStep(e)} className="w-full md:w-[700px] m-auto">
      <h1 className="font-semibold text-xl text-left">Personal details</h1>

      <div className="flex flex-col items-start w-full mt-8">
        <p>Address</p>
        <Input
          style={{ minWidth: "100%" }}
          type="text"
          placeholder="Flat no / House no"
          required
          value={state.details.address}
          setValue={(val) =>
            setState({ ...state, details: { ...state.details, address: val } })
          }
        />
      </div>

      <div className="grid md:gap-6 md:grid-cols-2 md:mt-4">
        <div className="flex flex-col items-start w-full">
          <p>Country</p>
          <Input
            style={{ minWidth: "100%" }}
            type="text"
            required
            placeholder="Street name / Bulding no / Plot  no"
            value={state.details.country}
            setValue={(val) =>
              setState({
                ...state,
                details: { ...state.details, country: val },
              })
            }
          />
        </div>
        <div className="flex flex-col items-start w-full">
          <p>Region</p>
          <select
            style={{
              minWidth: "100%",
              border: "1px solid lightgray",
              padding: "0 0 0 1rem",
              margin: "0.7rem 0",
              color: "rgb(0,0,0,.7)",
            }}
            className="w-full min-h-[45px] md:min-h-[60px] rounded-md md:rounded-[15px]"
            value={state.details.state}
            onChange={(e) =>
              setState({
                ...state,
                details: { ...state.details, state: e.target.value },
              })
            }
          >
            <option value="">Select your region</option>
            <option value="male">{Constants.Regions[0]}</option>
            <option value="female">{Constants.Regions[1]}</option>
            <option value="other">{Constants.Regions[2]}</option>
            <option value="other">{Constants.Regions[3]}</option>
            <option value="other">{Constants.Regions[4]}</option>
            <option value="male">{Constants.Regions[5]}</option>
            <option value="female">{Constants.Regions[6]}</option>
            <option value="other">{Constants.Regions[7]}</option>
            <option value="other">{Constants.Regions[8]}</option>
            <option value="other">{Constants.Regions[9]}</option>
            <option value="other">{Constants.Regions[10]}</option>
            <option value="other">{Constants.Regions[11]}</option>
            <option value="other">{Constants.Regions[12]}</option>
            <option value="other">{Constants.Regions[13]}</option>
            <option value="other">{Constants.Regions[14]}</option>
            <option value="other">{Constants.Regions[15]}</option>
          </select>
        </div>
        <div className="flex flex-col items-start w-full">
          <p>City</p>
          <Input
            style={{ minWidth: "100%" }}
            type="text"
            placeholder="Enter city name"
            required
            value={state.details.city}
            setValue={(val) =>
              setState({ ...state, details: { ...state.details, city: val } })
            }
          />
        </div>
        <div className="flex flex-col items-start w-full">
          <p>Phone Number</p>
          <Input
            style={{ minWidth: "100%" }}
            type="text"
            placeholder="Enter phone number"
            required
            value={state.details.phone}
            setValue={(val) =>
              setState({ ...state, details: { ...state.details, phone: val } })
            }
          />
        </div>
        <div className="flex flex-col items-start w-full">
          <p>Gender</p>
          <select
            style={{
              minWidth: "100%",
              border: "1px solid lightgray",
              padding: "0 0 0 1rem",
              margin: "0.7rem 0",
              color: "rgb(0,0,0,.7)",
            }}
            className="w-full min-h-[45px] md:min-h-[60px] rounded-md md:rounded-[15px]"
            required
            value={state.details.gender}
            onChange={(e) =>
              setState({
                ...state,
                details: { ...state.details, gender: e.target.value },
              })
            }
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className="flex flex-col items-start w-full">
          <p>Date of Birth</p>
          <Input
            style={{ minWidth: "100%" }}
            type="date"
            placeholder="Pick date of birth"
            required
            value={state.details.dob}
            setValue={(val) =>
              setState({ ...state, details: { ...state.details, dob: val } })
            }
          />
        </div>
      </div>
      <div className="w-full flex justify-between mt-8 mb-10">
        <Button
          style={{ minWidth: "200px" }}
          text={"Save and Continue"}
          onClick={() => {}}
          className={`bg-blue text-white`}
        />

        {/* {formFull() && (
          <div
            style={{ minWidth: "140px" }}
            onClick={() => {
              setStep(2, {
                gender: userInfo?.gender,
                dob: userInfo?.dob,
                phone: userInfo?.phone,
              });
              navigate(`/verify-account/2`);
            }}
            className={`text-blue border ml-2 border-blue h-[40px] flex items-center justify-center rounded-lg`}
          >
            Skip
          </div>
        )} */}
      </div>
    </form>
  );
}

export default KycOne;
